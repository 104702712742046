import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3eac8b6f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "menu-bar"
};
var _hoisted_2 = {
  class: "menu-bar-no-child"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "menu-bar-item-main-icon"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  class: "menu-bar-item-main-text"
};
var _hoisted_7 = {
  class: "menu-bar-has-child"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  class: "menu-bar-item-main-icon"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  class: "menu-bar-item-main-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menus, function (item, i) {
    return _openBlock(), _createElementBlock("div", {
      class: "menu-bar-item",
      key: i
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
      class: _normalizeClass(["menu-bar-item-main", {
        'menu-bar-item-main-active': item.check || $setup.index == i
      }]),
      onClick: function onClick($event) {
        return $setup.parentCheck($event, item, i);
      }
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("i", {
      class: "iconfont",
      innerHTML: item.icon
    }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(item[$props.property]), 1), item.children.length > 0 ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: _normalizeClass(["iconfont", {
        'iconfont-active': item.unfold
      }])
    }, "", 2)) : _createCommentVNode("", true)])], 10, _hoisted_3)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, function (child, childIndex) {
      return _openBlock(), _createElementBlock("div", {
        class: "menu-bar-item-child",
        key: childIndex
      }, [_createElementVNode("div", {
        class: "menu-bar-item-main",
        style: _normalizeStyle({
          background: child.check ? 'rgba(112, 185, 54, 0.1)' : '#ffffff',
          color: child.check ? '#70b936' : '#000000',
          height: '51px',
          borderRadius: '0'
        }),
        onClick: function onClick($event) {
          return $setup.childCheck(child, i);
        }
      }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("i", {
        class: "iconfont",
        innerHTML: child.icon
      }, null, 8, _hoisted_10)]), _createElementVNode("div", _hoisted_11, _toDisplayString(child[$props.property]), 1)], 12, _hoisted_8)]);
    }), 128))])]);
  }), 128))]);
}