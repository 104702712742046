import originRequest from "@/utils/request.js";
import { computed } from "vue";
import { Modal } from "ant-design-vue";
import { setSurveyStatus } from "../../ProjectManage/api";
import { getQuesByPages } from '../Design/js/util.js';
import store from "../../../store";
import fileDownload from 'js-file-download';
import router from "../../../router";

const sn = computed(() => router.currentRoute.value.query.sn || '');
let openModal = false;
function confirm() {
  const status = store.state.common.surveyStatus;



  return new Promise((resolve, reject) => {
    let modalText = '';
    if (status === 1) {
      modalText = '问卷运行中，修改内容将暂停回收数据，修改完毕后需再次发布';
    } else if (status === 2) {
      modalText = '问卷已结束，编辑后将重新开启'
    } else {
      resolve();
      return
    }
    if (openModal) return;
    openModal = true;
    Modal.confirm({
      title: "确认消息",
      content: modalText,
      cancelText: "取消",
      okText: "确认",
      class: "custom-modal custom-modal-title-confirm-notice",
      onOk: () => {
        console.log('sn', router.currentRoute.value);
        openModal = false;
        setSurveyStatus({
          sn: sn.value,
          status: 0
        }).then((data) => {
          store.commit("common/M_COMMON_SET_SURVEY_STATUS", +data.data.status);
        }).finally(() => {
          resolve();
        });
      },
      onCancel: async () => {
        console.log('sn', router.currentRoute.value);
        openModal = false;
        let { data = {} } = await getQuestionList(sn.value);
        if (data.questions) {
          console.log('data', data);
          data.survey.pages = data.survey.pages || [[]];
          const combineList = getQuesByPages(data.questions, data.survey.pages);
          data.questions = combineList;
          store.commit('common/A_COMMON_SET_QUESTIONINFO', JSON.stringify(data));
          store.commit('common/M_COMMON_SET_SURVEY_STATUS', data.survey.status);
          //  console.log('store.state.activeQuestion',);

          if (store?.state?.common?.activeQuestion) {
            const result = data.questions.find(s => s?.id === store?.state?.common?.activeQuestion?.id) || undefined;
            if (result) {
              store.commit('common/A_COMMON_SET_ACTIVEQUESTION', JSON.stringify(result));
            }
          }
        }
        reject("Canceled");
      }
    });
  });
}

function request(...rest) {
  return confirm().then(() => originRequest(...rest)).catch((error) => Promise.reject(error || "Canceled"));
}


/* 获取问题列表 */
export function getQuestionList(params, code) {
  return originRequest({
    headers: {
      'survey-invite-code': code || '',
    },
    url: `/console/surveys/${params}/questions`,
    method: "get",
  });
}

/* 保存问题 */
export function saveQuestion(params) {
  return request({
    url: `/console/surveys/${sn.value}/questions`,
    method: "post",
    data: params
  });
}

/* 级联题csv上传 */
export function uploadCascadeCsv(params) {
  return request({
    url: `/console/cascade_import`,
    method: "post",
    data: params.data
  });
}

/* 问卷标题，介绍语，结束语配置 */
export function updateSurveyConfig(params) {
  return request({
    url: `/console/surveys/${sn.value}/details`,
    method: "post",
    data: params.data
  });
}

/* 作答设置 - 查询 */
export function getAnswerSetting(sn) {
  return originRequest({
    url: `/console/surveys/${sn}/answer_setting`,
    method: "get"
  });
}

/* 作答设置 - 设置*/
export function setAnswerSetting(sn, params) {
  return request({
    url: `/console/surveys/${sn}/answer_setting`,
    method: "put",
    data: params
  });
}

/* 级联提下载csv*/
export function downLoadCsv(params) {
  return request({
    url: `/console/template/${params}`,
    method: "get",
    responseType: 'arraybuffer'
  });
}

/* 文件类型*/
export function fileType(params) {
  return request({
    url: `/console/file_type`,
    method: "get",
  });
}

/* Maxdiff生成设计*/
export function designs(params) {
  return request({
    url: `/console/surveys/${params.sn}/questions/${params.question_index}/designs`,
    method: "post",
    data: params
  });
}

/* cbc生成设计*/
export function cbcDesigns(params) {
  return request({
    url: `/console/surveys/${params.sn}/questions/${params.question_index}/cbc_designs`,
    method: "post",
    data: params
  });
}


/* Maxdiff导入设计*/
export function mxd_import(params) {
  return request({
    url: `/console/surveys/${params.get("sn")}/questions/${params.get("question_index")}/mxd_import`,
    method: "post",
    data: params
  });
}

/* 3d货架导入设计*/
export function cbc_shelves_import(params) {
  return request({
    url: `/console/surveys/${params.get("sn")}/questions/${params.get("question_index")}/cbc_shelves_import`,
    method: "post",
    data: params
  });
}


/* Maxdiff导出设计*/
const mxd_export = async (params) => {
  const url = params.url;
  //  await request({
  //   url: `/console/surveys/${params.sn}/questions/${params.question_index}/mxd_export`,
  //   method: "post",
  //   data: params
  // });
  const data = await request({
    url,
    method: "get",
    headers: {
      //'token': '...', // 根据需求添加
      'Content-type': 'application/x-www-form-urlencoded'
    },
    responseType: 'arraybuffer'
  })
  fileDownload(data, "", 'application/vnd.ms-excel');
}


/* bpto导入设计*/
export function bpto_import(params) {
  return request({
    url: `/console/bpto_import`,
    method: "post",
    data: params
  });
}

/* bpto导入设计*/
const template = async (params, name) => {
  const data = await request({
    url: `/console/template/${params.type}`,
    fileName: name,
    headers: {
      //'token': '...', // 根据需求添加
      'Content-type': 'application/x-www-form-urlencoded'
    },
    responseType: 'arraybuffer'
  });
  fileDownload(data, name, 'application/vnd.ms-excel');
}
export { template, mxd_export };



/* bpto导入设计*/
export function getProcess(params) {
  return request({
    url: `/console/surveys/${params.sn}/process`,
    method: "get",
    params
  });
}


/* bpto导入设计*/
export function postProcess(params) {
  return request({
    url: `/console/surveys/${params.sn}//process`,
    method: "post",
    data: params
  });
}
/* 3d货架资源列表*/
export function getImgList() {
  return request({
    url: `/console/shelves_resources`,
    method: "get",
  });
}


/* 删除*/
export function deleteDesigns(params) {
  return request({
    url: `/console/surveys/${params.sn}/questions/${params.question_index}/designs`,
    method: "DELETE",
    data: params
  });
}
/* 流程控制问题列表*/
export function processControlQuesList(sn) {
  return request({
    url: `/console/surveys/${sn}/process_questions`,
  });
}
