import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { ref, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
export default {
  name: "LeftMenuBar",
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    property: {
      type: String,
      default: "name"
    }
  },
  activated: function activated() {
    var route = useRoute();
    var path = route.path;
    this.menus.forEach(function (x) {
      x.check = x.path === path;
    });
  },
  setup: function setup(props, context) {
    var index = ref(null);
    var route = useRoute();
    var menus = props.list.map(function (first) {
      var path = route.path;
      first.check = path === first.path;
      first.unfold = false;
      first.children = first.children.map(function (second) {
        second.check = false;
        return second;
      });
      return first;
    });

    var _domHandle = domHandle(menus, context),
        parentCheck = _domHandle.parentCheck,
        childCheck = _domHandle.childCheck;

    watch(function () {
      return route.path;
    }, function () {
      console.log('menus', menus);
      menus = menus.map(function (first) {
        var path = route.path;
        first.check = path === first.path; // first.unfold = false;
        // first.children = first.children.map((second) => {
        //   second.check = false;
        //   return second;
        // });

        return first;
      });
    }); // 点击一级菜单名称

    var menuName = ref('');

    function domHandle(menus, context) {
      // 点击一级菜单
      var parentCheck = function parentCheck(e, item, i) {
        if (menuName.value !== item.name) {
          menusStatusToFalsefather(menus);
        }

        menuName.value = item.name;
        if (item.disable) return;

        if (item.children.length > 0) {
          item.unfold = !item.unfold;
          var $checkDom = e.currentTarget.parentElement.nextElementSibling;
          $checkDom.style.height = item.unfold ? "".concat($checkDom.scrollHeight, "px") : 0;
          return;
        }

        if (item.check) return;
        menusStatusToFalse(menus);
        item.check = true;
        index.value = i;
        context.emit("menuCheck", item);
      }; // 点击二级菜单


      var childCheck = function childCheck(child, i) {
        if (!!child.check) return;
        menusStatusToFalse(menus);
        context.emit("menuCheck", child);
        child.check = true;
        index.value = i;
      };

      return {
        parentCheck: parentCheck,
        childCheck: childCheck
      };
    }

    return {
      menus: menus,
      index: index,
      parentCheck: parentCheck,
      childCheck: childCheck
    };
  }
};

function menusStatusToFalse(menus) {
  menus.forEach(function (x) {
    x.check = false;

    if (x.children) {
      menusStatusToFalse(x.children);
    }
  });
}

function menusStatusToFalsefather(menus) {
  menus.forEach(function (x) {
    x.unfold = false;
    var menubar = document.getElementsByClassName('menu-bar-has-child');

    for (var key = 0; key < menubar.length; key++) {
      menubar[key].style.height = '0px';
    }
  });
}