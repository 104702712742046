import { saveQuestion } from '../../api/api.js'
import { SocketObserver } from '../../../../utils/websocket.js'
import * as cheerio from 'cheerio';
const Base64 = require('js-base64').Base64;

let observer = null;
/**
 * 保存整个数据接口
 * @param {Object} quesSaveParam 保存的参数,包含问题列表question,逻辑列表logics,分页列表pages
 * @param {Object} store
 */
export const saveQuesApi = (quesSaveParam, store) => {
  const ws = store.state.common.websocket;
  if (observer) {
    ws.socket.remove(observer)
  }
  if (ws.socket.ws.readyState && ws.socket.ws.readyState === 1) {
    ws.param = {
      is_cache: 0,
    }
  }
  store.commit(
    "common/A_COMMON_SET_WEBSOCKET",
    ws
  );
  ws.socket.listenClose(() => {
    ws.param = {
      is_cache: 1,
    }
    store.commit(
      "common/A_COMMON_SET_WEBSOCKET",
      ws
    );
  })
  const newFunc = debounce(() => {
    if (
      quesSaveParam.newQuestion.length === 0 &&
      quesSaveParam.newSurvey.pages.length === 0
    ) {
      return;
    }
    quesSaveParam.newSurvey.version = Base64.encode(new Date().getTime() + '');

    console.log("调用接口保存", quesSaveParam);
    saveQuestion({
      data: {
        logics: quesSaveParam.newLogics,
        survey: quesSaveParam.newSurvey,
        questions: quesSaveParam.newQuestion,
      }
    });
    ws.socket.send(store.state.common.questionInfo?.survey?.sn || '');
    observer = new SocketObserver();
    ws.socket.add(observer);
    observer.listen(function (evt) {
      console.log('接受消息', evt.data);
      if (evt.data !== 'ping') {
        ws.param = JSON.parse(evt.data);
        store.commit(
          "common/A_COMMON_SET_WEBSOCKET",
          ws
        );
      }
    })
    console.log("清除");
    store.commit(
      "common/A_COMMON_SET_QUESSAVEPARAM",
      JSON.stringify({
        newLogics: [],
        newQuestion: [],
        newSurvey: {
          pages: [],
          version: '',
        },
      })
    );
  }, 500)
  newFunc()
}
/**
 * 将接口返回的题目和分页两个数组合并为一个
 * @param {Array} quesList 要合并的题目
 * @param {Array} pages 分页完成的题目id数组
 * @param {Array} pagesStr 分页的内容数组
 * @returns
 */
export function combineQuesAndPage(quesList, pages, pagesStr) {
  if (pages.length === 0) return quesList;
  const newPages = `${pages.join(',empty,')},empty`.split(',').filter((x) => x.length !== 0);
  const copyList = quesList.filter((x) => x.id);
  let startIndex = 0;
  newPages.forEach((x, index) => {
    if (x === 'empty') {
      copyList.splice(index, 0, pagesStr[startIndex]);
      startIndex++;
    }
  });
  return copyList;
}

/**
 * 根据原始数据pages和问题列表quesList处理成包含分页的quesList
 * @param {Array} quesList 要合并的题目
 * @param {Array} pages 分页完成的题目ques_index数组
 * @returns
 */
export function getQuesByPages(quesList, page) {
  const page_str = [];
  page.forEach((x, xIndex) => {
    const temp = {
      page: xIndex + 1,
      total: page.length,
      first_title: '',
      last_title: '',
    }
    x.forEach((y, yIndex) => {
      if (yIndex === 0) {
        temp.first_title = quesList.find((ques) => ques.question_index === y)?.title || '';
      }
      if (yIndex === x.length - 1) {
        temp.last_title = quesList.find((ques) => ques.question_index === y)?.title || '';
      }
    })
    page_str.push(temp);
  })
  const result = combineQuesAndPage(quesList, page, page_str);
  return result;
}

/**
 * 根据包含分页的问题列表quesList算出pages
 * @param {Array} quesList 包含分页数据的问题列表
 * @returns
 */
export function getPagesByQues(quesList) {
  const resultPage = [];
  let arr = [];
  quesList.forEach((ques) => {
    if (ques.id) {
      arr.push(ques.question_index);
    } else {
      resultPage.push(arr);
      arr = [];
    }
  })
  return resultPage;
}

/**
 * 根据包含分页的问题列表quesList,算出更新后的quesList
 * @param {Array} quesList 包含分页的问题列表quesList
 * @returns
 */
export function getPageQuesByQues(quesList) {
  const page = getPagesByQues(quesList);
  const filteQues = quesList.filter((ques) => ques.id);
  return {
    page,
    resultList: getQuesByPages(filteQues, page),
  }
}
/**
 * 根据原始问题列表quesList,手动分页的pages, 算出包含逻辑和手动分页的quesList
 * @param {Array} quesList 原始问题列表quesList
 * @param {Array} pages 接口返回的，手动添加的分页
 * @returns
 */
export function getPageQuesByQuesLogic(quesList, pages) {
  const newPages = `${pages.join(",empty,")},empty`
    .split(",")
    .filter((x) => x.length !== 0);
  const copyPages = [...newPages];
  newPages.forEach((p, pIndex) => {
    if (p !== "empty") {
      if (
        quesList.find((q) => q.question_index === Number(p)).logics_has
      ) {
        if (newPages[pIndex] !== "empty") {
          copyPages.splice(pIndex, 0, "empty");
        }
        if (newPages[pIndex + 1] !== "empty") {
          copyPages.splice(pIndex + 1, 0, "empty");
        }
      }
    }
  });
  let startIndex = 0;
  const resultPage = []
  copyPages.forEach((cp, index) => {
    if (cp === 'empty') {
      let sliceArr = copyPages.slice(startIndex, index).map((x) => {
        x = Number(x);
        return x
      })
      resultPage.push(sliceArr);
      startIndex = index + 1;
    }
  })
  return getQuesByPages(quesList, resultPage);
}
export function getOffset(obj) {
  let offsetT = 0;
  while (obj != window.document.body && obj != null) {
    offsetT += obj.offsetTop;
    obj = obj.offsetParent;
  }
  return offsetT;
}

/**
 * @description: 判断dom元素是否在可视区域内
 * @param: el: Vuecomponnet对象
 * @returns: true-在可视区域，false: 不在可视区域
 */
export const isElementInViewport = function (el) {
  if (!el) return true;
  const scrollEl = document.getElementsByClassName('design-content-main')[0];
  let s = getOffset(el);    // 元素相对于页面顶部的距离
  let x = el.offsetHeight    //元素自身高度
  let t = scrollEl.scrollTop  // 滚动盒子在垂直方向上滚动的距离
  let y = scrollEl.clientHeight + 1000  //滚动盒子可视区域的高度
  let isHidden = (s + x) <= t || (s >= (t + y))
  return !isHidden
}
export const dragFunc = function (drag) {
  if (!drag) {
    console.error('元素不存在');
    return
  }
  drag.onmousedown = function (e) {
    //鼠标按下触发
    var disx = e.pageX - drag.offsetLeft; //获取鼠标相对元素距离
    var disy = e.pageY - drag.offsetTop;
    document.onmousemove = function (e) {
      e.stopPropagation();
      //鼠标移动触发事件，元素移到对应为位置
      drag.style.left = e.pageX - disx + "px";
      drag.style.top = e.pageY - disy + "px";
    };
    document.onmouseup = function () {
      //鼠标抬起，清除绑定的事件，元素放置在对应的位置
      e.stopPropagation();
      document.onmousemove = null;
      document.onmousedown = null;
    };
  };
}
/**
 * 去掉富文本标签及空格，图片标签替换为文字
 * @param {富文本标签} html 
 * @returns
 */
export function nodeHandle(html) {
  const $ = cheerio.load(html);
  $("img").replaceWith('<p>[图片]</p>');
  return $.text().trim();
}
/**
 * 获取浏览器url后指定的参数
 * @param {要获取的浏览器参数} name 
 * @returns 
 */
export function getUrlKey(name) {
  let returnVal = ''
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const href = location.href.split("?")[1] ? location.href.split("?")[1] : null
  if (href) {
    returnVal = href.match(reg);
    if (returnVal) {
      return returnVal[2]
    }
  }
  return ''
}
/**
 *  防抖函数
 * @param {Function} func 要执行的函数
 * @param {Number} delay 延迟时间
 * @returns
 */
function debounce(func, delay) {
  let timeout;
  return function (e) {
    clearTimeout(timeout);
    let context = this, args = arguments
    timeout = setTimeout(function () {
      func.apply(context, args);
    }, delay)
  };
};
/**
 * 获取新增选项的名称
 * @param {optionList} Array 选项数组
 * @param {startOptStr} String 选项的默认起始值，如选择题默认开头为选项1，选项2...，矩阵题默认开头为行标签1，行标签2...
 * @returns
 */
 export function getOptionName(optionList, startOptStr = '选项') {
  let result = '';
  const optionCounts = [];
  const oldOptionCounts = [];
  optionList.forEach((opt, index) => {
    const $opt = cheerio
      .load(opt.option)
      .text()
      .replace(/\s*/g, "")
      .replaceAll("\n", "");
    const reg = new RegExp(`${startOptStr}\\d+`, "g");
    const regExp = reg.test($opt);
    if (regExp) {
      optionCounts.push(Number($opt.replace(startOptStr, '').trim()));
    }
    oldOptionCounts.push(index + 1);
  });
  for (let index = 0; index < oldOptionCounts.length; index++) {
    const element = oldOptionCounts[index];
    if (!optionCounts.includes(element)) {
      result = element;
      break;
    }
  }
  let length = optionList.length;
  return result || length + 1
};