const { socketUrl } = require('@/config');
function InitWebsocket (e) {
  let url = socketUrl;
  this.observers = [];
  this.ws = new WebSocket(url);
  this.ws.onopen = (evt) => {
    console.log('建立连接');
    const self = this;
    const interval = setInterval(() => {
      if (self.ws.readyState === 1) {
        self.ws.send('ping');
      } else {
        clearInterval(interval);
      }
    }, 20000);
    for (let o of this.observers) {
      o.openBack(this);
    }
  };
  this.ws.onclose = function (evt) {
    console.log('关闭连接');
  }
  this.ws.onmessage = (evt) => {
    for (let o of this.observers) {
      o.update(evt);
    }
  }
  this.ws.onerror = (evt) => {
    console.log('连接错误', evt);
  }
}
InitWebsocket.prototype.add = function (observer) {
  this.observers.push(observer);
}
InitWebsocket.prototype.remove = function (observer) {
  this.observers.splice(this.observers.indexOf(observer), 1);
}
InitWebsocket.prototype.close = function () {
  this.ws.close();
}
InitWebsocket.prototype.send = function (params) {
  if (typeof (params) === 'string') {
    this.ws.send(params);
  } else {
    this.ws.send(JSON.stringify({ ...params }));
  }
}
InitWebsocket.prototype.listenClose = function (callback) {
  this.ws.onclose = callback;
}
InitWebsocket.prototype.addParam = function (param) {
  this.param = param;
}

function SocketObserver (type = 0) {
  this.openBack = null;
  this.update = null;
  this.type = 1;
}
SocketObserver.prototype.listen = function (callBack) {
  this.update = callBack;
}
SocketObserver.prototype.open = function (callBack) {
  this.openBack = callBack;
}
export {
  InitWebsocket,
  SocketObserver,
}
